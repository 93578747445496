<template>
  <div class="container-fluid">
    <!-- Scrim comes in opposite the drawer -->
    <div v-if="!noScrim" :class="[right ? 'left' : 'right', show ? 'enter' : '']" class="scrim"
         @click="$emit('update:show', false)">
    </div>
    <div ref="drawer"
         :class="[right ? 'right' : 'left flex-row-reverse', {enter: show}, targetResizeClass, drawerClasses]"
         class="drawer d-flex"
         :style="{width: drawerWidth}">
      <column-resize-handle v-if="resizable"
                            :columnKey="targetResizeClass"
                            :columnWidth="drawerWidth"
                            @column-resized="$emit('update:drawerWidth', `${$event.columnWidth}px`)"
                            :debounce-ms="9"
                            :reverse="right ? true : false"
                            class="resizable d-flex">
        <fluency-icon class="text-success align-self-center icon-lg" type="ellipses" />
      </column-resize-handle>
      <div v-else class="non-resizable d-flex">
        <fluency-icon class="text-super-muted align-self-center icon-lg" type="ellipses" />
      </div>
      <div class="h-100 d-flex flex-column" style="width: calc(100% - 20px)" :class="bodyClass">
        <div>
          <slot name="header">
            <h3 class="m-0">
              <div class="d-flex-center-start">
                <div class="flex-grow-1">
                  <slot name="title">
                    <span>{{ title }}</span>
                  </slot>
                </div>
                <slot name="helpButton">
                </slot>
                <slot name="closeButton">
                  <b-button variant="link"
                            class="drawer-close-button text-black-50 pr-0"
                            :class="closeBtnClasses"
                            @click="$emit('update:show', false); $emit('close')">
                    <fluency-icon type="x" />
                  </b-button>
                </slot>
              </div>
            </h3>
          </slot>
        </div>
        <h4 v-if="contentTitle || ($slots.contentTitle && $slots.contentTitle())" class="card-title mt-3">
          <slot name="contentTitle">{{contentTitle}}</slot>
        </h4>
        <h6 v-if="contentDescription || ($slots.contentDescription && $slots.contentDescription())" class="card-subtitle my-2 text-muted">
          <slot name="contentDescription">{{contentDescription}}</slot>
        </h6>
        <div class="flex-grow-1 overflow-auto-y mt-3">
          <slot></slot>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ColumnResizeHandle from 'core-ui/components/common/columnResizeHandle.vue'
import _debounce from 'lodash.debounce'

export default {
  name: 'drawer',
  components: { ColumnResizeHandle },
  compatConfig: {
    INSTANCE_SCOPED_SLOTS: false
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    right: {
      type: Boolean
    },
    drawerWidth: {
      type: String,
      default: '75%'
    },
    title: {
      type: String
    },
    contentTitle: {
      type: String
    },
    contentDescription: {
      type: String
    },
    bodyClass: {
      type: String,
      default: 'pr-4'
    },
    drawerClasses: {
      type: String,
      default: ''
    },
    noScrim: {
      type: Boolean,
      default: false
    },
    sizeTracker: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: true
    },
    closeBtnClasses: {
      type: String
    }
  },
  data () {
    return {
      targetResizeClass: 'drawer_' + Math.round(Math.random() * 1000),
      resizeObserver: null
    }
  },
  mounted () {
    try {
      if (this.sizeTracker) {
        this.resizeObserver = new ResizeObserver(this.onResize).observe(this.$refs.drawer)
      }
    } catch (e) {
      // do nothing (old browser)
    }
    document.addEventListener('keyup', this.keyUpHandler)
  },
  beforeUnmount () {
    try {
      if (this.sizeTracker && this.resizeObserver) {
        this.resizeObserver.unobserve(this.$refs.drawer)
      }
    } catch (e) {
      // do nothing (old browser)
    }
  },
  unmounted () {
    document.removeEventListener('keyup', this.keyUpHandler)
  },
  methods: {
    onResize: _debounce(function () {
      if (this.sizeTracker) {
        this.$emit('visible-size', this.$refs?.drawer?.clientWidth)
      }
    }, 250),
    keyUpHandler (evt) {
      if (evt.defaultPrevented) {
        return
      }
      const key = evt.key || evt.keyCode
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        if (this.show) { // do not emit this unless the drawer is open, it can trigger other things inadvertently. -SNG
          this.$emit('update:show', false)
          evt.preventDefault()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  @mixin sliding-content() {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    transition: transform ease-in-out 0.38s, visibility 0.38s, width 0.38s;
    will-change: none;
  }

  .scrim {
    @include sliding-content();
    background-color: $fluency-gray;
    opacity: 0.7;
    z-index: 1029;
  }

  .drawer {
    @include sliding-content();
    background-color: $background;
    z-index: 1035;
    display: flex;
    min-width: 10vw;
  }

  .left {
    border-right: 1px solid $fluency-gray;
    left: 0;
    transform: translateX(-102%);
  }

  .right {
    border-left: 1px solid $fluency-gray;
    right: 0;
    transform: translateX(102%);
  }

  .enter {
    pointer-events: inherit;
    visibility: visible;
    transform: translateX(0%);
  }

  .overflow-auto {
    overflow: auto
  }

  .alert-z-index {
    z-index: 10100 !important;
  }

  .drawer-close-button {
    font-size: 1.125rem;

    &:hover {
      color:black !important;
    }
  }
</style>
